import { Toaster } from 'react-hot-toast';
// import nProgress from 'nprogress';
import { useRoutes } from 'react-router-dom';
import routes from './routes';
import { CacheProvider } from '@emotion/react';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
// import { LocalizationProvider } from '@mui/lab';
// import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { SettingsButton } from '../components/settings-button';
import { SplashScreen } from '../components/splash-screen';
import { SettingsConsumer, SettingsProvider } from '../contexts/settings-context';
import { AuthConsumer, AuthProvider } from '../contexts/GardianWebSSOAuthContext';
import { createTheme } from '../theme';
import { createEmotionCache } from '../utils/create-emotion-cache';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import numeral from 'numeral';// load a locale
// switch between locales
// numeral.locale('fr');

// Router.events.on('routeChangeStart', nProgress.start);
// Router.events.on('routeChangeError', nProgress.done);
// Router.events.on('routeChangeComplete', nProgress.done);

const clientSideEmotionCache = createEmotionCache();

const App = (props) => {
  const content = useRoutes(routes);
  const { Component, emotionCache = clientSideEmotionCache, pageProps } = props;

  // const getLayout = Component.getLayout ?? ((page) => page);

  return (
    <CacheProvider value={emotionCache}>
      <HelmetProvider >
        <Helmet>
          <title>
            Administration Smartborne
          </title>
          <meta
            name="viewport"
            content="initial-scale=1, width=device-width"
          />
        </Helmet>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <AuthProvider>
            <SettingsProvider>
              <SettingsConsumer>
                {({ settings }) => (
                  <ThemeProvider
                    theme={createTheme({
                      mode: settings.theme
                    })}
                  >
                    <CssBaseline />
                    <Toaster position="top-center" />
                    <SettingsButton />
                    <AuthConsumer>
                      {(auth) => !auth.isInitialized
                        ? <SplashScreen />
                        : content}
                    </AuthConsumer>
                  </ThemeProvider>
                )}
              </SettingsConsumer>
            </SettingsProvider>
          </AuthProvider>
        </LocalizationProvider>
      </HelmetProvider>
    </CacheProvider>
  );
};

export default App;

import React from 'react'
import PropTypes from 'prop-types'
import { useAuth } from '../../hooks/use-auth';


const ACL = ({ allowedRoles, isNot, children, fallback }) => {

    const { user } = useAuth()
    const roles = user.userProfile || null
    const condition = roles?.some(role => allowedRoles.includes(role)) && !roles.some(role => isNot.includes(role))
    if (condition && children) return children
    else if (fallback) return fallback
    else return null
}

ACL.propTypes = {
    allowedRoles: PropTypes.array.isRequired,
    children: PropTypes.node.isRequired
}

ACL.defaultProps = {
    allowedRoles: [],
    isNot: []
};

export default ACL
import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthGuard } from '../components/authentication/auth-guard';
import ACL from '../components/authentication/ACL';
import LoadingScreen from '../components/LoadingScreen';
import { DashboardLayout } from '../components/dashboard/dashboard-layout';
import { DocsLayout } from '../components/docs/docs-layout';

const Loadable = (Component) => (props) => (
  <Suspense fallback={<LoadingScreen />}>
    <Component {...props} />
  </Suspense>
);

// // Dashboard pages

const General = Loadable(lazy(() => import('../pages/dashboard/')));
const Account = Loadable(lazy(() => import('../pages/dashboard/account')));

// const Station = Loadable(lazy(() => import('../pages/dashboard/station/')));
// const StationList = Loadable(lazy(() => import('../pages/dashboard/station/list')));
// const StationEditOrCreate = Loadable(lazy(() => import('../pages/dashboard/station/edit')));
const Maps = Loadable(lazy(() => import('../pages/dashboard/map')));
const Planner = Loadable(lazy(() => import('../pages/dashboard/planner')));
const Logistics = Loadable(lazy(() => import('../pages/dashboard/logistics')));
// const CustomerDetails = Loadable(lazy(() => import('./pages/dashboard/CustomerDetails')));
// const CustomerEdit = Loadable(lazy(() => import('./pages/dashboard/CustomerEdit')));

const BorneList = Loadable(lazy(() => import('../pages/dashboard/borne/list')));
const BorneDetails = Loadable(lazy(() => import('../pages/dashboard/borne/index')));
const BorneEditOrCreate = Loadable(lazy(() => import('../pages/dashboard/borne/edit')));

const BorneImageList = Loadable(lazy(() => import('../pages/dashboard/borne_image/list')));
const BorneImage = Loadable(lazy(() => import('../pages/dashboard/borne_image/index')));
const BorneImageEdit = Loadable(lazy(() => import('../pages/dashboard/borne_image/edit')));

const BorneIAList = Loadable(lazy(() => import('../pages/dashboard/borne_ia/list')));
const BorneIA = Loadable(lazy(() => import('../pages/dashboard/borne_ia/index')));
const BorneIAEdit = Loadable(lazy(() => import('../pages/dashboard/borne_ia/edit')));

const BorneAdvertisingList = Loadable(lazy(() => import('../pages/dashboard/borne_advertising/list')));
const BorneAdvertising = Loadable(lazy(() => import('../pages/dashboard/borne_advertising/index')));
const BorneAdvertisingEditOrCreate = Loadable(lazy(() => import('../pages/dashboard/borne_advertising/edit')));

const BorneRewardList = Loadable(lazy(() => import('../pages/dashboard/borne_reward/list')));
const BorneReward = Loadable(lazy(() => import('../pages/dashboard/borne_reward/index')));
const BorneRewardEditOrCreate = Loadable(lazy(() => import('../pages/dashboard/borne_reward/edit')));

// const Finance = Loadable(lazy(() => import('./pages/dashboard/Finance')));
// const InvoiceDetails = Loadable(lazy(() => import('./pages/dashboard/InvoiceDetails')));
// const InvoiceList = Loadable(lazy(() => import('./pages/dashboard/InvoiceList')));
// const Kanban = Loadable(lazy(() => import('./pages/dashboard/Kanban')));
// const Mail = Loadable(lazy(() => import('./pages/dashboard/Mail')));
// const OrderDetails = Loadable(lazy(() => import('./pages/dashboard/OrderDetails')));
// const OrderList = Loadable(lazy(() => import('./pages/dashboard/OrderList')));
const User = Loadable(lazy(() => import('../pages/dashboard/user/')));
const UserList = Loadable(lazy(() => import('../pages/dashboard/user/list')));
const UserEdit = Loadable(lazy(() => import('../pages/dashboard/user/edit')));

// // Error pages
const AuthorizationRequired = Loadable(lazy(() => import('../pages/401')));
const NotFound = Loadable(lazy(() => import('../pages/404')));
const ServerError = Loadable(lazy(() => import('../pages/500')));

// Docs pages

const Docs = Loadable(lazy(() => import('../pages/docs/')));

const routes = [
  {
    path: '/',
    element: (
      <Navigate
        to="/dashboard/"
        replace
      />
    )
  },
  {
    path: '/dashboard/',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: <General />
      },
      {
        path: 'account',
        element: <Account />
      },
      {
        path: 'borne',
        children: [
          {
            path: '',
            element: <BorneList />
          },
          {
            path: ':borneId',
            element: <BorneDetails />
          },
          {
            path: ':borneId/edit',
            element: <BorneEditOrCreate />,
          }
        ]
      },
      {
        path: 'borne_map',
        element: (
          <Maps />
        )
      },
      {
        path: 'borne_image',
        children: [
          {
            path: '',
            element: <BorneImageList />
          },
          {
            path: ':borneImageId',
            element: <BorneImage />
          },
          {
            path: ':borneImageId/edit',
            element: <BorneImageEdit />,
          },
        ]
      },
      {
        path: 'borne_ia',
        children: [
          {
            path: '',
            element: <BorneIAList />
          },
          {
            path: ':borneImageId',
            element: <BorneIA />
          },
          {
            path: ':borneImageId/edit',
            element: <BorneIAEdit />,
          },
        ]
      },
      {
        path: 'borne_advertising',
        children: [
          {
            path: '',
            element: <BorneAdvertisingList />
          },
          {
            path: 'create',
            element: <BorneAdvertisingEditOrCreate />,
          },
          {
            path: ':id',
            element: <BorneAdvertising />
          },
          {
            path: ':id/edit',
            element: <BorneAdvertisingEditOrCreate />,
          },
        ]
      },
      {
        path: 'borne_reward',
        children: [
          {
            path: '',
            element: <BorneRewardList />
          },
          {
            path: 'create',
            element: <BorneRewardEditOrCreate />,
          },
          {
            path: ':id',
            element: <BorneReward />
          },
          {
            path: ':id/edit',
            element: <BorneRewardEditOrCreate />,
          },
        ]
      },
      {
        path: 'planner',
        element: (
          <Planner />
        )
      },
      {
        path: 'logistics',
        element: (
          <ACL allowedRoles={['8GE_ADMIN']} fallback={<AuthorizationRequired />}>
            <Logistics />
          </ACL>
        )
      },
      {
        path: 'user',
        children: [
          {
            path: '',
            element: (
              <ACL allowedRoles={['8GE_ADMIN']} fallback={<AuthorizationRequired />}>
                <UserList />
              </ACL>
            )
          },
          {
            path: ':userId',
            element: (
              <ACL allowedRoles={['8GE_ADMIN']} fallback={<AuthorizationRequired />}>
                <User />
              </ACL>
            )
          },
          {
            path: ':userId/edit',
            element: (
              <ACL allowedRoles={['8GE_ADMIN']} fallback={<AuthorizationRequired />}>
                <UserEdit />
              </ACL>
            ),
          }
        ]
      },

      // {
      //   path: 'mail',
      //   children: [
      //     {
      //       path: '/',
      //       element: (
      //         <Navigate
      //           to="/dashboard/mail/all"
      //           replace
      //         />
      //       )
      //     },
      //     {
      //       path: 'label/:customLabel',
      //       element: <Mail />
      //     },
      //     {
      //       path: 'label/:customLabel/:emailId',
      //       element: <Mail />
      //     },
      //     {
      //       path: ':systemLabel',
      //       element: <Mail />
      //     },
      //     {
      //       path: ':systemLabel/:emailId',
      //       element: <Mail />
      //     }
      //   ]
      // },
    ]
  },
  {
    path: 'docs',
    element: <DocsLayout />,
    children: [
      {
        path: '',
        element: (
          <Navigate
            to="/docs/welcome"
            replace
          />
        )
      },
      {
        path: '*',
        element: <Docs />
      }
    ]
  },
  {
    path: '401',
    element: <AuthorizationRequired />
  },
  {
    path: '404',
    element: <NotFound />
  },
  {
    path: '500',
    element: <ServerError />
  },
  {
    path: '*',
    element: <NotFound />
  }
];

export default routes;
